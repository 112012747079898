import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { globalHistory as history } from '@reach/router';

const Head = ({
  children,
  meta,
  canonical,
  metatitle,
  metadescription,
  currentLang,
  activeDocMeta,
  opengraphImage = 'https://secureprivacy.ai/images/meta/tile.png',
}) => {
  const url = 'https://secureprivacy.ai/';
  const { location } = history;
  const contentType = activeDocMeta?.type;

  const opengraphUrl = canonical?.text;
  const opengraphTitle = metatitle?.text;
  const [hrefLangs, setHrefLangs] = useState([]);
  const [defaultHrefLangs, setDefaultHrefLangs] = useState(null);
  const opengraphDescription = metadescription?.text;

  const ogType = useMemo(() => {
    switch (contentType) {
      case 'blogpostpage':
        return 'article';
      case 'product':
        return 'product';
      default:
        return 'website';
    }
  }, [contentType]);

  useEffect(() => {
    const allHrefLangs =
      activeDocMeta &&
      (activeDocMeta?.alternate_languages || []).map((val) => {
        const completePath = url + location.pathname;
        var myRegexp = /^(.*\/)/g;
        var match = myRegexp.exec(completePath);

        const completPaths =
          val.type === 'homepage'
            ? completePath
            : match[1] + (val.uid ? val.uid : '');

        if (val.lang.substring(0, 2) == 'en') {
          return {
            completePath: completPaths
              .replace('/de/', '')
              .replace('/pt/', '')
              .replace('/fr/', '')
              .replace('.ai//', '.ai/'),
            lang: val.lang.substring(0, 2),
          };
        }
        if (val.lang.substring(0, 2) == 'pt') {
          return {
            completePath: completPaths
              .replace('.ai//', '.ai/pt/')
              .replace('/de/', 'pt/')
              .replace('/fr/', 'pt/')
              .replace('ptpt', 'pt'),
            lang: val.lang.substring(0, 2),
          };
        }
        if (val.lang.substring(0, 2) == 'de') {
          return {
            completePath: completPaths
              .replace('.ai//', '.ai/de/')
              .replace('/pt/', 'de/')
              .replace('/fr/', 'de/')
              .replace('dede', 'de'),
            lang: val.lang.substring(0, 2),
          };
        }

        if (val.lang.substring(0, 2) == 'fr') {
          return {
            completePath: completPaths
              .replace('.ai//', '.ai/fr/')
              .replace('/pt/', 'fr/')
              .replace('/de/', 'fr/')
              .replace('frfr', 'fr'),
            lang: val.lang.substring(0, 2),
          };
        }
      });

    const completePath = url + location.pathname;
    const defaulLang = {
      completePath: completePath
        .replace(
          '/pt/',
          currentLang.substring(0, 2) == 'en'
            ? ''
            : `${currentLang.substring(0, 2)}/`
        )
        .replace(
          '/de/',
          currentLang.substring(0, 2) == 'en'
            ? ''
            : `${currentLang.substring(0, 2)}/`
        )
        .replace(
          '/fr/',
          currentLang.substring(0, 2) == 'en'
            ? ''
            : `${currentLang.substring(0, 2)}/`
        )
        .replace('.ai//', '.ai/'),
      lang: currentLang.substring(0, 2),
    };
    let defaultLang;
    if (allHrefLangs) {
      defaultLang = [...allHrefLangs, defaulLang].filter(
        (val) => val && val.lang == 'en'
      );
    }
    if (!allHrefLangs) {
      defaultLang = defaulLang;
    }

    const hrefLangComplete = allHrefLangs
      ? [...allHrefLangs, defaulLang]
      : [defaulLang];

    setDefaultHrefLangs(defaultLang);
    setHrefLangs(hrefLangComplete);
  }, [activeDocMeta, currentLang, location.pathname]);

  // Only update the title once
  useEffect(() => {
    if (metatitle?.text) {
      document.title = metatitle.text;
    } else if (meta?.title) {
      document.title = meta.title;
    }
  }, [metatitle, meta]);

  return (
    <Helmet>
      {/* Encoding and styles */}
      <html lang={currentLang.substring(0, 2)} />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={
          defaultHrefLangs &&
          defaultHrefLangs.length &&
          defaultHrefLangs[0].completePath
        }
      />
      {hrefLangs.map((val) => {
        return (
          <link
            rel="alternate"
            hrefLang={val.lang}
            href={val.completePath}
            key={val.lang}
          />
        );
      })}
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes"
        name="viewport"
      />

      {/* HTML Meta Tags */}
      <meta
        name="title"
        content={metatitle?.text ? metatitle.text : meta?.title}
        data-react-helmet="true"
      />

      <meta
        name="description"
        content={
          metadescription?.text ? metadescription.text : meta?.description
        }
        data-react-helmet="true"
      />

      <link rel="canonical" href={opengraphUrl ? opengraphUrl : url} />

      {/* Facebook meta */}
      <meta content={url} property="og:site_name" />
      <meta content={opengraphTitle} property="og:title" />
      <meta content={opengraphDescription} property="og:description" />
      <meta content={ogType} property="og:type" />
      <meta content={opengraphImage} property="og:image" />
      <meta content={opengraphUrl} property="og:url" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter meta */}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={opengraphTitle} name="twitter:title" />
      <meta content={opengraphDescription} name="twitter:description" />
      <meta content={opengraphImage} property="twitter:image" />
      <meta content={opengraphUrl} name="twitter:url" />
      <meta content={url} name="twitter:site" />

      {/* Specified tags */}
      {children}
    </Helmet>
  );
};

Head.defaultProps = {
  meta: {
    title: `Secure Privacy: GDPR, CCPA, LGPD & cookie compliance solution`,
    description: `Scan websites for GDPR, CCPA & cookie compliance for free. Add privacy policies & cookie banners to your website in minutes.`,
  },
};

Head.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  currentLang: PropTypes.string.isRequired,
  activeDocMeta: PropTypes.any,
  canonical: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  metatitle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  metadescription: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  opengraphImage: PropTypes.string,
};

export default Head;
