import React, { useState, useContext } from 'react';
import style from './Menu.module.scss';
import { array, bool, string } from 'prop-types';
import classnames from 'classnames';
import MenuItem from '../MenuItem/MenuItem';
import LangContext from '@contexts';
import { langPath } from '@helpers';
import { globalHistory as history } from '@reach/router';
import NewMenuItem from '../NewMenuItem/NewMenuItem';

const Menu = ({ data, open }) => {
  const [activeMenu, setActiveMenu] = useState('');

  const menuclass = classnames({
    [style.menu]: true,
    [style.open]: open,
  });
  const currentLang = useContext(LangContext);
  const { location } = history;

  const handleActiveMenu = (title = '') => {
    if (title === activeMenu) {
      setActiveMenu('');
    } else {
      setActiveMenu(title);
    }
  };

  const [isLinkActive, setIsLinkActive] = useState(false);
  const link = langPath(currentLang) + '/' + 'blog';
  location.pathname === link && setIsLinkActive(true);

  const classItem = classnames({
    [style.link]: true,
    [style.linkActive]: isLinkActive,
  });

  return (
    <menu className={menuclass}>
      {data &&
        data?.map((itemm, index) => {
          if (itemm?.slice_type === 'menu') {
            return (
              <>
                <MenuItem
                  {...itemm}
                  key={index}
                  index={index}
                  activeMenu={activeMenu}
                  handleActiveMenu={handleActiveMenu}
                />
              </>
            );
          } else if (itemm?.slice_type === 'menu_single') {
            return (
              <div key={index} className={classItem}>
                {itemm.items.map((val, i) => {
                  return (
                    <a
                      key={i}
                      href={`${val.menu_single_link.url}`}
                      rel="noreferrer"
                      className={style.link}
                    >
                      {val.menu_single_title.richText[0].text}
                    </a>
                  );
                })}
              </div>
            );
          } else if (itemm?.slice_type === 'newmenu') {
            return (
              <>
                <NewMenuItem
                  {...itemm}
                  key={index}
                  index={index}
                  activeMenu={activeMenu}
                  handleActiveMenu={handleActiveMenu}
                />
              </>
            );
          }
        })}
    </menu>
  );
};

Menu.propTypes = {
  data: array,
  open: bool,
  location: string,
};

export default Menu;
