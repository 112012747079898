import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import style from './Navigation.module.scss';
import { langPath } from '@helpers';
import { array } from 'prop-types';
import LangContext from '@contexts';

const Navigation = ({ data }) => {
  const currentLang = useContext(LangContext);
  const [visibleItems, setVisibleItems] = useState(15);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleViewAllClick = () => {
    setVisibleItems(data[0]?.items?.length);
    setIsExpanded(true);
  };

  const handleViewLessClick = () => {
    setVisibleItems(15);
    setIsExpanded(false);
  };

  return (
    <nav className={style.nav}>
      <div className={style.wrapper}>
        {data.slice(0, 1).map(({ primary, items }) => {
          const title = primary.title.text;
          return (
            <>
              <div className={style.container}>
                <div className={style.items} key={title}>
                  <h1 className={style.title}>{title}</h1>
                  <ul className={style.list}>
                    {items
                      .slice(0, 17)
                      .map(({ name, pagename, externallink }) => {
                        const linkName = name.text;
                        const link =
                          (!externallink.url && langPath(currentLang)) +
                          '/' +
                          pagename.text;
                        return (
                          <li className={style.link} key={linkName}>
                            {externallink.url ? (
                              <a href={externallink.url} target="_self">
                                {linkName}
                              </a>
                            ) : (
                              <Link to={link}>{linkName}</Link>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {isExpanded && (
                  <div className={style.item} key={title}>
                    <ul className={style.expandedlist}>
                      {items
                        .slice(17, items?.length)
                        .map(({ name, pagename, externallink }) => {
                          const linkName = name.text;
                          const link =
                            (!externallink.url && langPath(currentLang)) +
                            '/' +
                            pagename.text;
                          return (
                            <li className={style.link} key={linkName}>
                              {externallink.url ? (
                                <a href={externallink.url} target="_self">
                                  {linkName}
                                </a>
                              ) : (
                                <Link to={link}>{linkName}</Link>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </div>
            </>
          );
        })}
        {data[0]?.items?.length > visibleItems && (
          <>
            <p
              className={style.viewAllButton}
              onClick={() => handleViewAllClick()}
            >
              See more
            </p>
          </>
        )}
        {isExpanded && (
          <p
            className={style.viewLessButton}
            onClick={() => handleViewLessClick()}
          >
            Show Less
          </p>
        )}
      </div>
      {data.slice(1, 4).map(({ primary, items }) => {
        const title = primary.title.text;
        return (
          <div className={style.item} key={title}>
            <h1 className={style.title}>{title}</h1>
            <ul className={style.list}>
              {items.map(({ name, pagename, externallink }) => {
                const linkName = name.text;
                const link =
                  (!externallink.url && langPath(currentLang)) +
                  '/' +
                  pagename.text;
                return (
                  <li className={style.link} key={linkName}>
                    {externallink.url ? (
                      <a href={externallink.url} target="_self">
                        {linkName}
                      </a>
                    ) : (
                      <Link to={link}>{linkName}</Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </nav>
  );
};

Navigation.propTypes = {
  data: array,
};

export default Navigation;
